import React from "react"
import { graphql } from "gatsby"

import Layout from "src/components/layout"
import Seo from "src/components/seo"
import Header from "src/components/header"
import Footer from "src/components/footer"
import Mainsection from "src/components/section/main"
import Category from "src/components/faq/category"
import Faq from "src/components/faq/faq"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo />
    <Header />
    <main>
      <Mainsection title="よくあるご質問" subtitle="natadeCOCOに多く寄せられるご質問とその回答をご紹介します" />
      <Category />
      <Faq data={data} />
    </main>
    <Footer />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {frontmatter: {type: {eq: "faq"}, publish: {eq: true}, pickup: {gt: 0}}},
      sort: { fields: [frontmatter___pickup], order: ASC },
    ) {
      edges {
        node {
          id
          frontmatter {
            category
            tag
            title
          }
          html
        }
      }
    }
  }
`
