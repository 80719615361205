import React from "react"
import Styles from "./category.module.scss"
import { Link } from "gatsby"

const Category = ({ category }) => {
  const categories = [
    {
      name: "service",
      title: "サービス",
      icon: "fas fa-info-circle",
    },
    {
      name: "content",
      title: "コンテンツ配信",
      icon: "fas fa-broadcast-tower",
    },
    {
      name: "plan",
      title: "プラン・料金",
      icon: "fas fa-coins",
    },
    {
      name: "app",
      title: "アプリ",
      icon: "fas fa-tablet-alt",
    },
    {
      name: "support",
      title: "サポート",
      icon: "fas fa-users",
    },
    {
      name: "etc",
      title: "その他",
      icon: "far fa-question-circle",
    },
  ];

  const styleClass = (cate) => category && cate === category ? "has-background-link has-text-white" : "has-background-white has-text-primary";
  const linkPath = (cate) => category && cate === category ? "" : cate;

  const CategoryLinkDesktop = ({ category }) => (
    <Link to={`/faq/${linkPath(category.name)}`} className="is-flex is-hidden-mobile">
      <div className={`box is-paddingless ${styleClass(category.name)} ${Styles.box}`} style={{ width: "100%"}}>
        <div className="has-text-centered" style={{ padding: ".5rem" }}>
          <div className="is-size-1"><i className={category.icon}></i></div>
          <div className="is-size-6"><strong>{category.title}</strong></div>
          <div className="is-size-7" style={{marginBottom: ".5rem"}}>について</div>
        </div>
      </div>
    </Link>
  )

  const CategoryLinkMobile = ({ category }) => (
    <Link to={`/faq/${linkPath(category.name)}#items`} className="is-flex is-hidden-tablet">
      <div className={`box is-paddingless ${styleClass(category.name)} ${Styles.box}`} style={{ width: "100%"}}>
        <div style={{ padding: ".5rem 1rem" }}>
          <div className="columns is-vcentered is-mobile">
            <div className="column is-narrow"><span className="icon icon-large"><i className={`${category.icon} fa-lg`} /></span></div>
            <div className="column is-size-6 has-text-weight-bold">{category.title}について</div>
          </div>
        </div>
      </div>
    </Link>
  )

  return (
    <section className="section" style={{ paddingTop: "0", paddingBottom: "0" }}>
      <div className="container">
        <div className="columns is-multiline">
        {categories.map(cate => (
          <div className="column is-2" key={cate.name}>
            <div className={`is-flex ${Styles.item}`}>
              <CategoryLinkDesktop category={cate} />
              <CategoryLinkMobile category={cate} />
            </div>
          </div>
        ))}
        </div>
      </div>
    </section>
  );
}

export default Category
